import React from "react"

import "./styles.scss"

// import Moon from "./assets/moon.svg"
// import Sun from "./assets/sun.svg"

export default function DayNightToggle() {
  return (
    <>
      <label className="day-night-toggle">
        <input
          type="checkbox"
          name="appearance"
          onChange={event => {
            if (event.target.checked) {
              document.documentElement.style.setProperty(
                "--background-color",
                "#232323"
              )
              document.documentElement.style.setProperty(
                "--brand-color",
                "turquoise"
              )
              document.documentElement.style.setProperty(
                "--text-color",
                "white"
              )
            } else {
              document.documentElement.style.setProperty(
                "--background-color",
                "white"
              )
              document.documentElement.style.setProperty(
                "--brand-color",
                "salmon"
              )
              document.documentElement.style.setProperty(
                "--text-color",
                "hsla(0, 0%, 0%, 0.8)"
              )
            }
          }}
        />
        <span className="night">{/* <Moon /> */}</span>
        <span className="knob" />
        <span className="day">{/* <Sun /> */}</span>
      </label>
    </>
  )
}
