import React from "react"

import Copy from "../Copy"
import Heading from "../Heading"
import Button from "../Button"
import Image from "../image"

import "./styles.scss"

const Spotlight = ({ reverse, title, content }) => {
  return (
    <div className={`spotlight ${reverse ? "spotlight--reverse" : ""}`}>
      <div className="spotlight-image">
        <Image name="banner.jpg" />
      </div>
      <div className="spotlight-text">
        <Heading type="h1">{title || "no title provided"}</Heading>

        <Copy source={content} />

        <Button size="large">get started</Button>
      </div>
    </div>
  )
}

export default Spotlight
