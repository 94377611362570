import React from "react"
import Image from "../image"
import Button from "../Button"
import Heading from "../Heading"
import Copy from "../Copy"
import DayNightToggle from "../DayNightToggle"

import "./styles.scss"
export default function Header() {
  return (
    <header className="header">
      <div className="header-image">
        <Image name="banner.jpg" />
      </div>
      <div className="header-text">
        <Heading type="h1">Super New Story</Heading>
        <Copy
          type="intro"
          source={`A (modular, highly tweakable) __responsive__ one-page [template](http://google.com) designed by
          HTML5 UP and released for free under the Creative Commons.`}
        />
        <Copy
          source={`This is a Banner element, and it's generally used as an introduction
          or opening statement. You can customize its style, scheme, [template](http://google.com),
          size, orientation, content alignment, and image position, as well as
          assign it an optional onload or onscroll transition modifier
          (details).`}
        />
        <DayNightToggle />
        <Button size="large">get started</Button>
        <Button
          size="large"
          onClick={() => {
            document.documentElement.style.setProperty(
              "--secondary-color",
              "salmon"
            )
          }}
        >
          Lachs
        </Button>
        <Button
          size="large"
          onClick={() => {
            document.documentElement.style.setProperty(
              "--secondary-color",
              "rebeccapurple"
            )
          }}
        >
          RebeccaPurple
        </Button>
      </div>
    </header>
  )
}
