/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import "./layout.css"
import Header from "./Header/"
import Spotlight from "./Spotlight/"
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header />
        <Spotlight
          title="Good night John-boy"
          content={`This is a Banner element, and it's generally used as an introduction
          or opening statement. You can customize its style, scheme, color,
          size, orientation, content alignment, and **image position, as well as**
          assign it an optional onload or onscroll transition modifier
          (details).`}
        />
        <Spotlight
          title="test"
          content={`This is a Banner element, and it's generally used as an introduction
          or opening statement. You can customize its style, scheme, color,
          size, orientation, content alignment, and image position, as well as
          assign it an optional onload or onscroll transition modifier
          (details).
          `}
          reverse
        />

        <Spotlight
          title="bla"
          content={`This is a Banner element, and it's generally used as an introduction
          or opening statement. You can customize its style, scheme, color,
          size, orientation, content alignment, and image position, as well as
          assign it an optional onload or onscroll transition modifier
          (details).`}
        />
        <Spotlight
          content={`This is a Banner element, and it's generally used as an introduction
          or opening statement. You can customize its style, scheme, color,
          size, orientation, content alignment, and image position, as well as
          assign it an optional onload or onscroll transition modifier
          (details).`}
          reverse
          title="blub"
        />

        <Spotlight
          content={`This is a Banner element, and it's generally used as an introduction
          or opening statement. You can customize its style, scheme, color,
          size, orientation, content alignment, and image position, as well as
          assign it an optional onload or onscroll transition modifier
          (details).`}
          title="something"
        />
        <Spotlight
          content={`This is a Banner element, and it's generally used as an introduction
          or opening statement. You can customize its style, scheme, color,
          size, orientation, content alignment, and image position, as well as
          assign it an optional onload or onscroll transition modifier
          (details).`}
          reverse
          title="special"
        />

        {children}
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
