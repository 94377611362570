import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import "./styles.scss"
const Button = ({ primary, disabled, fit, size, href, children, onClick }) => {
  return (
    <a
      href={href}
      onClick={onClick}
      className={classNames("button", {
        "button--primary": primary,
        "button--is-disabled": disabled,
        "button--fit": fit,
        "button--size-large": size === "large",
        "button--size-small": size === "small",
      })}
    >
      {children}
    </a>
  )
}

Button.propTypes = {
  primary: PropTypes.bool,
  disabled: PropTypes.bool,
  fit: PropTypes.bool,
  size: PropTypes.bool,
  href: PropTypes.string,
  children: PropTypes.string,
}

Button.defaultProps = {
  primary: false,
  disabled: false,
  fit: false,
  size: "",
  href: "#",
  children: "[no text defined]",
}

export default Button
