import React from "react"
import ReactMarkdown from "react-markdown"

import "./styles.scss"

const Copy = ({ source, type }) => {
  return (
    <div className={`copy ${type === "intro" ? "copy--intro" : ""}`}>
      <ReactMarkdown source={source} />
    </div>
  )
}

export default Copy
