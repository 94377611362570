import React from "react"
import "./styles.scss"

const Heading = ({ children, type }) => {
  return React.createElement(
    type,
    { className: `heading-${type}` },
    ...children
  )
}

export default Heading
